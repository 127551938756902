import React, { Component } from 'react'
import config from '../config';

//import images
//for section one
import img1 from "../assets/provider/first1.svg"
import img2 from "../assets/provider/first2.svg"
import img3 from "../assets/provider/first3.svg"
import img4 from "../assets/provider/first4.svg"
import img5 from "../assets/provider/first5.svg"
import playstore from "../assets/landing/playstore.svg"
import appstore from "../assets/landing/appstore.svg"

//for section 2
import bag from "../assets/provider/bag.svg"
import like from "../assets/provider/like.svg"
import message from "../assets/provider/message.svg"

class Provider extends Component {
    state = { 
        mouseX: 0,
        mouseY: 0,
        isSmallScreen: window.innerWidth >= 1024 ? false : true,
        fname:'',
        lname:'',
        email:'',
        desc:'',
        message:'',
     }
    openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
      };
    
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(this.state.fname && this.state.lname){  
                let res = await fetch(`${config.API_URL}/users`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                    fname: this.state.fname,
                    lname: this.state.lname,
                    email: this.state.email,
                    desc: this.state.desc,
                    formType:'provider',
                    }),
                });

                if (res.status === 200) {
                    this.setState({message : 'Thank you for reaching out to us We will get back to you soon'});
                    this.setState({fname:'',lname:'',email:'',desc:''});
                } else {
                    this.setState({message : "Some error occured"});
                }
            }
        } catch (err) {
          console.log(err);
        }
      };

     handleMouseMove = (event) => {
        this.setState({
          mouseX: event.clientX,
          mouseY: event.clientY,
        });
      };
    
      componentDidMount() {
        window.addEventListener('mousemove', this.handleMouseMove);
      }
    
      componentWillUnmount() {
        window.removeEventListener('mousemove', this.handleMouseMove);
      }
      
    render() {
        const { navigate } = this.props;
        return (
            <div className='ProviderContainer' >
                <div className='firstProvider'>
                <div className='firstInProvider'>
                    <div className='textCointainerForFirstInProvider'>
                        <h1>Providers</h1>
                        <p>MazingDay is your promotional and business-improvement tool at the same time!</p>
                        <p>We are ready to welcome professionals, or recognized specialists in any area of expertise*</p>
                        <h3>
                        *With commercial license and third party insurance.
                        </h3>
                        <div className='storebtnInFirst' > 
                        { this.state.isSmallScreen === false ?
                    <div className='storebtnInFirst' > 
                    <img src={playstore
                    } alt="playstorebtn" style={{
                        width: '180px',
                        marginRight: '10px',
                        cursor: "pointer",
                        zIndex: "1"
                        
                    }}
                    onClick={
                        ()=>{
                            this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")
                        }
                    }
                    
                    />
                    <img src={appstore}
                    alt="appstorebtn" style={{
                        width: '180px',
                        marginLeft: '10px',
                        cursor: "pointer",
                        zIndex: "1"
                    }}
                    onClick={
                        ()=>{
                            this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        }
                    }
                    />

                    </div>
                    :
                    <button className='downloadInFirst' 
                    
                    onClick={()=>{
                        if(this.whatMobile === "ios" || this.whatMobile === "unknown" )
                        this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        else
                        this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")

                    }}
                    >
                    Download the app
                    </button>
                    }

                    </div>
                    </div>
                        {this.state.isSmallScreen === false ?                
                            <div className='ImageContainerInFirstInProvider' >
                            
                                    <div className='firstInProvider' style={{ width: '269px', height: '270px', marginLeft: "20%", transform: `rotateY(${(this.state.mouseX / window.innerWidth) * 100 - 50}deg) rotateX(${-(this.state.mouseY / window.innerHeight) * 100 + 40}deg)`,  }}>
                                        <img src={img1} alt="" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <div className='firstInProvider' style={{ width: '184px', height: '201px', marginTop: "-2%",transform: `rotateY(${(this.state.mouseX / window.innerWidth) * 100 - 50}deg) rotateX(${-(this.state.mouseY / window.innerHeight) * 100 + 40}deg)`, }}>
                                        <img src={img2} alt="" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <div className='firstInProvider' style={{ width: '331px', height: '332px',transform: `rotateY(${(this.state.mouseX / window.innerWidth) * 100 - 40}deg) rotateX(${-(this.state.mouseY / window.innerHeight) * 100 + 40}deg)`, }}>
                                        <img src={img3} alt="" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <div className='firstInProvider' style={{ width: '311px', height: '313px', marginTop: "-15%", marginLeft: "10%", transform: `rotateY(${(this.state.mouseX / window.innerWidth) * 100 - 40}deg) rotateX(${-(this.state.mouseY / window.innerHeight) * 100 + 40}deg)`,}}>
                                        <img src={img4} alt="" style={{ width: '100%', height: '100%' }} />
                                    </div>
                                    <div className='firstInProvider' style={{ width: '312px', height: '342px' }}>
                                    </div>
                                    <div className='firstInProvider' style={{ width: '312px', height: '342px',marginTop: "-15%",transform: `rotateY(${(this.state.mouseX / window.innerWidth) * 100 - 50}deg) rotateX(${-(this.state.mouseY / window.innerHeight) * 100 + 40}deg)`, }}>
                                        <img src={img5} alt="" style={{ width: '100%', height: '100%', zIndex: "1" }} />
                                    </div>
                                   
                            </div>
                        :
                        ""
                        } 
                </div>
                </div>
                <div className='secondInProvider'
                
                style={{
                    flexWrap: this.state.isSmallScreen ? "wrap" : "" ,
                }}
                >
                    <img src={bag} alt="bag"
                    style={{
                        order: this.state.isSmallScreen ? "1": "",
                    }}
                    />
                        <div className='hoverTextContainerInSecondInProvider'>
                            <div className='contentInTextContainer' >
                                <h1>
                                How can I benefit from joining MazingDay network?
                                </h1>
                                {!this.state.isSmallScreen ?
                                <div className='pInBoxOneInprovider'>
                                    <p>
                                    MazingDay is a wonderful tool for you, as it matches your offer of activities or services with the right customer base. Thus, you can:

                                </p>
                                <ul>
                                        <li>
                                        Increase your visibility with relevant and useful reviews, adhering to our code of conduct
                                        </li>
                                        <li>
                                        Increase your visitor and sales conversion rates, providing additional revenue
                                        </li>
                                        <li>
                                        Make new business connections
                                        </li>
                                        <li>
                                        Find potential new talents amongst your visitors
                                        </li>
                                    </ul></div>
                                : null }
                            </div>
                     </div>
                     

                </div>
                {this.state.isSmallScreen ?

                <div className='ProviderMobileBox1'>
                <p>
                MazingDay is a wonderful tool for you, as it matches your offer of activities or services with the right customer base. Thus, you can:
                </p>
                <ul>
                    <li>
                    Increase your visibility with relevant and useful reviews, adhering to our code of conduct
                    </li>
                    <li>
                    Increase your visitor and sales conversion rates, providing additional revenue
                    </li>
                    <li>
                    Make new business connections
                    </li>
                    <li>
                    Find potential new talents amongst your visitors 
                    </li>
                </ul>
                </div>
                :null }

                <div className='ThirdInProvider'>
                    
                        <div className='hoverTextContainerInThirdInProvider'
                        >
                            <div className='contentInTextContainer' >
                                <h1>
                                Amazing! But how does it actually work for me?
                                </h1>
                                {!this.state.isSmallScreen ?
                                <><p>
                                    The first step is to get to know each other. You can easily get in touch with us through the <span
                                        style={{
                                            color: "#448DF0",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            navigate('/contact');
                                        } }
                                    > contact form</span>.</p><p>
                                        We will then get in touch with you, to know more about you, the activities or services that you provide, and how we can offer them on our app. Once we have completed these few steps together, your activities or services will be showcased on MazingDay mobile app, thus making you an official member of our network. And of course, it's totally free!
                                    </p></>
                                :null}
                                
                            </div>
                     </div>
                    <img src={like} alt="bag"
                    style={{
                        order: this.state.isSmallScreen ? "3": "",
                    }}
                    />
                </div>
                {this.state.isSmallScreen ?

                    <div className='ProviderMobileBox2'>
                    <p>
                    The first step is to get to know each other. You can easily get in touch with us through the <span
                                        style={{
                                            color: "#448DF0",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            navigate('/contact');
                                        } }
                                    > contact form </span>.
                        We will then get in touch with you, to know more about you, the activities or services that you provide, and how we can offer them on our app. Once we have completed these few steps together, your activities or services will be showcased on MazingDay mobile app, thus making you an official member of our network. And of course, it's totally free!
                        </p>
                    </div>
                    :null }
                <div className='fourInProvider'>
                    <img src={message} alt="bag"
                    style={{
                        order: this.state.isSmallScreen ? "5": "",
                    }}
                    />
                        <div className='hoverTextContainerInFourInProvider'>
                            <div className='contentInTextContainer' >
                                <h1>
                                How can I join MazingDay's provider network?
                                </h1>
                                {!this.state.isSmallScreen ?

                                <p
                                className='pInBoxOneInprovider'
                                >
                                    We can’t wait for you to join our network! Please contact us through the <span
                                        style={{
                                            color: "#448DF0",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            navigate('/contact');
                                        } }
                                    > contact form </span> and tell us more about your activities or services. We will be more than happy to hear from you.
                                </p>
                                : null}
                                
                            </div>
                     </div>
                </div>
                {this.state.isSmallScreen ?

                <div className='ProviderMobileBox3'>
                    <p>
                    We can’t wait for you to join our network! Please contact us through the <span
                                        style={{
                                            color: "#448DF0",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            navigate('/contact');
                                        } }
                                    > contact form </span> and tell us more about your activities or services. We will be more than happy to hear from you.
                    </p>
                </div>
                :null }
                <div
            className='contancContainer'
            >
                <div className='textInContactProvider' style={{
                    marginTop: ".5rem"
                    }}>
                    <h1>Contact us</h1>
                    <br/>
                    <p>
                    Need to get in touch with us? Either fill out the form with your inquiry or send us a <span
                    style={{
                        color: "#448DF0",
                        cursor: "pointer"
                    }}
                    onClick={()=>{
                        window.location.href = 'mailto:info@mazingday.com'
                    }}
                    > direct email</span>.
                    </p>
                </div>
                <div className='inputInContact'>
                    {/* <form onSubmit={this.handleSubmit}> */}
                        <input
                        placeholder='First Name*'
                        type="text"
                        value={this.state.fname}
                        onChange={(e) => this.setState({fname : e.target.value})}
                        />
                        <input
                        placeholder='Last Name*'
                        type="text"
                        value={this.state.lname}
                        onChange={(e) => this.setState({lname : e.target.value})}
                        />
                        <input
                        placeholder='Email'
                        type="text"
                        value={this.state.email}
                        onChange={(e) => this.setState({email : e.target.value})}
                        />
                        <textarea
                        type="text"
                        value={this.state.desc}
                        style={{
                            height: "150px"
                        }}
                        onChange={(e) => this.setState({desc : e.target.value})}
                        cols="40" rows="5"
                        />
                        <button onClick={this.handleSubmit}>
                            contact us
                        </button>
                        <div className="message">{this.state.message ? <p>{this.state.message}</p> : null}</div>

                    {/* </form> */}

                </div>
            </div>
                
            </div>
        );
    }
}

export default Provider;