import React, { Component } from 'react';
import Tilt from 'react-parallax-tilt';


//import images
import first from "../assets/landing/first1.png"
import playstore from "../assets/landing/playstore.svg"
import appstore from "../assets/landing/appstore.svg"

//for section 2
import phones from "../assets/landing/phoneemojie.png"
import point1 from "../assets/landing/point1.svg"
import point2 from "../assets/landing/point2.svg"
import point3 from "../assets/landing/point3.svg"

//for section 3
import listImage1 from "../assets/landing/listImage1.png"
import listImage2 from "../assets/landing/listImage2.png"
import listImage3 from "../assets/landing/listImage3.png"
import listImage4 from "../assets/landing/listImage4.png"
import listImage5 from "../assets/landing/listImage5.png"
import happyface from "../assets/landing/happyface.svg"
import cal from "../assets/landing/cal.svg"
import dollarsign from "../assets/landing/dollarsign.svg"

//for section 4
import review from "../assets/landing/review2.svg"
import social from "../assets/landing/social1.png"

//for section 5 
import cal2 from "../assets/landing/cal2.svg"
import thing from "../assets/landing/thing.svg"
import filter from "../assets/landing/filter.svg"

//for six
import background from "../assets/landing/backgroungfordownload.svg"

class Landing extends Component {

    state = { 
        isSmallScreen: window.innerWidth >= 1100 ? false : true,
      };
      openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
      };

      whatMobile() {
        const userAgent = navigator.userAgent;
        const devices = {
          windowsPhone: /windows phone/i,
          android: /android/i,
          ios: /iPad|iPhone|iPod/
        };
      
        for (const device in devices) {
          if (devices[device].test(userAgent)) {
            return device;
          }
        }
      
        return 'unknown';
      }

      
    render() {
        
        return (
            <div className='landingContainer' >
                <div className='First' 
                // style={{
                //     marginTop: this.state.isSmallScreen? '100px' : '0px',
                // }}
                >

                    <div className='textContainerinFirst'>
                    <h1>
                    Discover something new about yourself.
                    </h1>
                    <p>
                    MazingDay is an app that offers a personalized selection of new activities to try in your city. </p>
                    <p>
                    Save the hassle of planning and making itineraries, MazingDay does it for you.
                    </p>
                    <p>
                    Optimize your free time, and make the most of your day.
                    </p>
                    { this.state.isSmallScreen === false ?
                    <div className='storebtnInFirst' > 
                    <img src={playstore
                    } alt="playstorebtn" style={{
                        width: '180px',
                        marginRight: '10px',
                        cursor: "pointer",
                        zIndex: "1"
                        
                    }}
                    onClick={
                        ()=>{
                            this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")
                        }
                    }
                    
                    />
                    <img src={appstore}
                    alt="appstorebtn" style={{
                        width: '180px',
                        marginLeft: '10px',
                        cursor: "pointer",
                        zIndex: "1"
                    }}

                    onClick={
                        ()=>{
                            this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        }
                    }
                    />

                    </div>
                    :
                    <button className='downloadInFirst' 
                    
                    onClick={()=>{
                        if(this.whatMobile === "ios" || this.whatMobile === "unknown" )
                        this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        else
                        this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")

                    }}
                    >
                    Download the app
                    </button>
                    }
                    </div>


                    {this.state.isSmallScreen === false ?
                    <div className='ImageFirst'>
                    <Tilt
                    
                    tiltMaxAngleX={10}
                    tiltMaxAngleY={10}
                    perspective={1000}
                    transitionSpeed={2000}
                    scale={1.1}
                    gyroscope={true}
                    gyroscopeMinAngleX={-10}
                    gyroscopeMaxAngleX={10}
                    gyroscopeMinAngleY={-10}
                    gyroscopeMaxAngleY={10}

                    >
                    <img src={first} alt="first"
                    draggable="false"
                    style={{
                        width: '700px',
                        height: '596px'}}
                    
                    />
                    </Tilt>
                    </div>
                    :
                    ""
                    }

                </div>

                <div className="second">
                        <div className="textContainerInSecond">
                        <h5>
                        How it works.
                        </h5>
                        <h1>
                        Personalized suggestions based on how you feel today.
                        </h1>
                        <div className='point1'>
                        <img src={point1} alt
                        ="point1"/>
                        <p>Start with outlining your interests, your mood, and your budget.</p>
                        </div>
                        <div className='point2'>
                        <img src={point2} alt
                        ="point2"/>
                        <p>Let MazingDay surprise you with suggestions based on your filters.</p>
                        </div>
                        <div className='point3'>
                        <img src={point3} alt
                        ="point3"/>
                        <p>Pick the options that you like and start making new memories!</p>
                        </div>
                    </div>

                    
                <img src={phones}  
                className="phoneSecond"
                alt="phones" style={{
                    marginTop: "10%",
                    width: '779px',
                }}/>
                </div>

                <div className='third'>
                    <div className='textContainerInThird'>
                        <p>
                        A personalized experience.
                        </p>
                        <h1>
                        Thousands of exciting options, all catered to you.
                        </h1>
                    </div>

                    <div className='thirdImageContainer'>
                     
                            <Tilt>
                            <img src={listImage1}
                             alt="listImage1"
                             draggable="false"
                             style={{
                                width: this.state.isSmallScreen === false ? "324px": "200px" ,
                                height: this.state.isSmallScreen === false ? "417px" : "250px",
                                borderRadius: '33px',
                                marginRight: '30px'
                             }}
                             />

                            </Tilt>
                            
                            <Tilt>
                            <img src={listImage2}
                             alt="listImage2"
                             draggable="false"
                             style={{
                                width: this.state.isSmallScreen === false ? "324px": "200px" ,
                                height: this.state.isSmallScreen === false ? "417px" : "250px",
                                borderRadius: '33px',
                                marginRight: '30px'
                             }}
                             />

                            </Tilt>
                            
                            <Tilt>
                            <img src={listImage3}
                             alt="listImage3"
                             draggable="false"
                             style={{
                                width: this.state.isSmallScreen === false ? "324px": "200px" ,
                                height: this.state.isSmallScreen === false ? "417px" : "250px",
                                borderRadius: '33px',
                                marginRight: '30px'
                             }}
                             />
                             </Tilt>
                             
                            <Tilt>
                            <img src={listImage4}
                             alt="listImage4"
                             draggable="false"
                             style={{
                                width: this.state.isSmallScreen === false ? "324px": "200px" ,
                                height: this.state.isSmallScreen === false ? "417px" : "250px",
                                borderRadius: '33px',
                                marginRight: '30px'
                             }}
                             />

                            </Tilt>
                            
                            <Tilt>
                            <img src={listImage5}
                             alt="listImage5"
                             style={{
                                width: this.state.isSmallScreen === false ? "324px": "200px" ,
                                height: this.state.isSmallScreen === false ? "417px" : "250px",
                                borderRadius: '33px',

                             }}
                             />

                            </Tilt>
                           
                    </div>

                    <div className='thingsWeDoInThird'>
                        <div className='itemInThingWeDo'>
                        <div className='fr' >
                            <img src={happyface}
                            alt="happyface"
                            />
                            
                            <h1>
                            Activities in all categories.
                            </h1>
                            </div>
                            <p>Whether it is water sports, museums, or pottery - MazingDay covers just about everything for everyone, no matter what you’re interested in.</p></div>
                        
                        <div className='itemInThingWeDo'>
                        <div className='fr'>
                            <img src={cal}
                            alt="cal"
                            />
                            
                            <h1>Up-to-date events that keep you in the loop.</h1>
                            </div>
                            <p>
                            A comedy show, a marathon or a fashion show... get to know everything happening around you.
                            </p></div>
                        
                        <div className='itemInThingWeDo'>
                        <div className='fr'>
                            <img src={dollarsign}
                            alt="dollarsign"
                            />
                            
                            <h1>Zero sponsorship or paid listings.</h1></div>
                            <p>
                            MazingDay doesn’t promote any listing so you can rest assured that every suggestion is a genuine recommendation based on your interests.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='four' >
                    <div className='textContainerInFour'>
                        <p>Customers love us.</p>
                        <h1>
                        Helping hundreds of people in Dubai satisfy their adventurous cravings.
                        </h1>
                        <br/>
                        <img src={review} 
                        draggable= 'false'
                        className='imageInFour'
                        alt="review"
                       
                        />
                    </div>
                    
                    {
                        this.state.isSmallScreen === false ? 
                        <img src={social} alt="social"
                        draggable= 'false'
                        className='socialImage'
                        style={{
                            position: "absolute",
                            right: "0",
                        }}
                        />
                        :
                        ""
                    }
                </div>
                <div className='five'>
                    <div className='textContainerInFive'>
                        <p>
                        More features you’ll love.
                        </p>
                        <h1>
                        Cut through the clutter and 
                        save time when it comes to
                        planning activities.
                        </h1>
                    </div>
                    
                    <div className='thingsWeDoInThird'>
                        <div className='itemInThingWeDo'>
                        <div className='fr' >
                            <img src={cal2}
                            alt="happyface"
                            />
                            
                            <h1>
                            Smart calendar planning and syncing.
                            </h1>
                            </div>
                            <p>Sync your calendar so that we only offer activities available during your free time, when you don’t have any meetings or other plans scheduled.</p></div>
                        
                        <div className='itemInThingWeDo'>
                        <div className='fr'>
                            <img src={filter}
                            alt="cal"

                            />
                            
                            <h1>Advanced filters for specific use cases.</h1>
                            </div>
                            <p>
                            Indicate the number of people that will be joining you on your activities and get kid-friendly suggestions for families.
                            </p></div>
                        
                        <div className='itemInThingWeDo'>
                        <div className='fr'>
                            <img src={thing}
                            alt="dollarsign"
                            />
                            
                            <h1>Daily options to 
                                always keep you on your toes.</h1></div>
                            <p>
                            With thousands of 
                            options, you’ll never be short of a next idea. 
                            We keep the 
                            recommendations 
                            coming so you can 
                            keep going!
                            </p>
                        </div>
                    </div>

                </div>
                <div className='six'>
                        <div className='textContainerInSix'
                        style={{
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                        >   
                            <p>
                            Start making new memories.
                            </p>
                            <h1>
                            Enjoy a free 15-day trial when you sign up!
                            </h1>
                            <p>
                            Plans starting at 6.99/mo USD
                            </p>
                            <button 
                            className='donwloadButton'
                            onClick={()=>{
                                if(this.whatMobile === "ios" || this.whatMobile === "unknown" )
                                this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                                else
                                this.openInNewTab(" https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")
        
                            }}
                            >
                            Download the app
                            </button>
                        </div>
                        
            
                </div>



                

            </div>
        );
    }
}

export default Landing;