import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';

// Imports from assets
import logo from '../assets/logo.svg';
import playstore from "../assets/landing/playstore.svg";
import appstore from "../assets/landing/appstore.svg";

function ResponsiveAppBar({ navigate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMenu, setSelectedMenu] = React.useState('home');
  const open = Boolean(anchorEl);

  function whatMobile() {
    const userAgent = navigator.userAgent;
    const devices = {
      windowsPhone: /windows phone/i,
      android: /android/i,
      ios: /iPad|iPhone|iPod/
    };
    for (const device in devices) {
      if (devices[device].test(userAgent)) {
        return device;
      }
    }
    return 'unknown';
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSmallScreen = useMediaQuery('(max-width:1024px)');

  return (
    <AppBar position="static" className='nevHeader'
      style={{
        backgroundColor: "#fff",
        boxShadow: isSmallScreen ? "0px 10px 12px -4px rgba(76, 179, 254, 0.13)" : "none",
      }}
    >
      <Toolbar>
        {isSmallScreen ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <Box mr={2}>
              <img alt="logo" src={logo}
                style={{ width: '60px', height: '60px' }}
                onClick={() => navigate('/')}
              />
            </Box>
            <IconButton
              edge="start"
              color="black"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { navigate('/'); setSelectedMenu('home'); handleClose(); }}
                style={{ borderBottom: "0.4px solid lightblue", width: "100%", fontWeight: selectedMenu === 'home' ? 'bold' : 'normal' }}
              >Home</MenuItem>
              <MenuItem onClick={() => { navigate('/provider'); setSelectedMenu('provider'); handleClose(); }}
                style={{ borderBottom: "0.4px solid lightblue", width: "100%", fontWeight: selectedMenu === 'provider' ? 'bold' : 'normal' }}
              >I am a provider</MenuItem>
              <MenuItem onClick={() => { navigate('/contact'); setSelectedMenu('contact'); handleClose(); }}
                style={{ borderBottom: "0.4px solid lightblue", width: "100%", fontWeight: selectedMenu === 'contact' ? 'bold' : 'normal' }}
              >Contact</MenuItem>
              <MenuItem onClick={() => { navigate('/faq'); setSelectedMenu('faq'); handleClose(); }}
                style={{ borderBottom: "0.4px solid lightblue", width: "100%", fontWeight: selectedMenu === 'faq' ? 'bold' : 'normal' }}
              >FAQs</MenuItem>
              <MenuItem>
                <button
                  className="download-btn"
                  style={{
                    border: "none",
                    borderRadius: "5px",
                    color: "#fff",
                    padding: "10px 20px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                  }}
                  onClick={() => {
                    if (whatMobile() === "ios" || whatMobile() === "unknown")
                      openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                    else
                      openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")
                    handleClose();
                  }}
                >
                  Download the app
                </button>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <>
            <Box mr={2}>
              <img alt="logo" src={logo}
                style={{ width: '151.5px', height: '130.56px' }}
                onClick={() => navigate('/')}
              />
            </Box>
            <Box mr={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                gap: "10%",
              }}
            >
              <Box mr={2} className={selectedMenu === 'home' ? 'selectedMenu' : ''} onClick={() => { setSelectedMenu('home'); navigate('/'); }}>
                <h2 style={{ color: '#000', fontWeight: selectedMenu === 'home' ? 'bold' : 'normal', fontFamily: "Montserrat", fontSize: "20px", cursor: "pointer" }}>Home</h2>
              </Box>
              <Box mr={2} className={selectedMenu === 'provider' ? 'selectedMenu' : ''} onClick={() => { setSelectedMenu('provider'); navigate('/provider'); }}>
                <h2 style={{ color: '#000', fontWeight: selectedMenu === 'provider' ? 'bold' : 'normal', fontFamily: "Montserrat", fontSize: "20px", cursor: "pointer" }}>I am a provider</h2>
              </Box>
              <Box mr={2} className={selectedMenu === 'contact' ? 'selectedMenu' : ''} onClick={() => { setSelectedMenu('contact'); navigate('/contact'); }}>
                <h2 style={{ color: '#000', fontWeight: selectedMenu === 'contact' ? 'bold' : 'normal', fontFamily: "Montserrat", fontSize: "20px", cursor: "pointer" }}>Contact</h2>
              </Box>
              <Box mr={2} className={selectedMenu === 'faq' ? 'selectedMenu' : ''} onClick={() => { setSelectedMenu('faq'); navigate('/faq'); }}>
                <h2 style={{ color: '#000', fontWeight: selectedMenu === 'faq' ? 'bold' : 'normal', fontFamily: "Montserrat", fontSize: "20px", cursor: "pointer" }}>FAQs</h2>
              </Box>
            </Box>
          </>
        )}
        {!isSmallScreen ?
          <div className='logoNevLaptop'>
            <div className='storebtnInFirst'>
              <img src={playstore} alt="playstorebtn" style={{ width: '180px', marginRight: '10px', cursor: "pointer", zIndex: "1" }}
                onClick={() => { openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1") }} />
              <img src={appstore} alt="appstorebtn" style={{ width: '180px', marginLeft: '10px', cursor: "pointer", zIndex: "1" }}
                onClick={() => { openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415") }} />
            </div>
          </div>
          : ""}
      </Toolbar>
    </AppBar>
  );
}

export default ResponsiveAppBar;