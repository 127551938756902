import "./global.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "./components/navbar";
import Footer from "./components/footer";
import "@fontsource/montserrat";

//screens
import Landing from "./screens/landing";
import Provider from "./screens/provider";
import Contact from "./screens/contact";
import Policy from "./screens/policy";
import Faq from "./screens/faq";
import Terms from "./screens/terms";
import Faqv2 from "./screens/faqv2";

function App() {
  const navigate = useNavigate();
  const excludedPaths = ["/privacy-policy", "/terms-and-conditions", "/faq-on-application"];
  const isMobile = window.innerWidth < 1024;
  const currentPath = window.location.pathname;
  const showNavBar = !excludedPaths.includes(currentPath) || !isMobile;

  return (
    <div>
      {showNavBar && <ResponsiveAppBar navigate={navigate} />}
      <Routes>
        <Route path="/" element={<Landing navigate={navigate} />}></Route>
        <Route path="/faq" element={<Faq navigate={navigate} />}></Route>
        <Route path="/provider" element={<Provider navigate={navigate} />}></Route>
        <Route path="/contact" element={<Contact navigate={navigate} />}></Route>
        <Route path="/privacy-policy" element={<Policy navigate={navigate} />}></Route>
        <Route path="/terms-and-conditions" element={<Terms navigate={navigate} />}></Route>
        <Route path="/faq-on-application" element={<Faqv2 navigate={navigate} />}></Route>
      </Routes>
      {showNavBar && <Footer navigate={navigate} />}
    </div>
  );
}

export default App;