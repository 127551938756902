import React, { Component } from 'react';
import logo from "../assets/logoWhite.svg";
import config from '../config';
import playstore from "../assets/landing/playstore.svg";
import appstore from "../assets/landing/appstore.svg";
import facebook from "../assets/social/1.svg";
import insta from "../assets/social/2.svg";
import linkedin from "../assets/social/3.svg";
import email from "../assets/social/email.svg";

class Footer extends Component {
    state = { 
        isSmallScreen: window.innerWidth < 1024,
    };

    openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    render() {
        const { navigate } = this.props;
        const { isSmallScreen } = this.state;

        const FooterContent = () => (
            <div className='baseFooter'>
                <div className='baseFooterContainer'>
                    <div className='footerIcons'>
                        <div className='footerLogoDetails' style={{ width: "25%" }}>
                            <img src={logo} alt="logo" style={{ width: "151.5px", height: "130.56px", marginLeft: "-3.5%" }} />
                            <br /><br />
                            <p style={{ color: "white", fontSize: "22px", fontWeight: "400" }}>Make any moment an opportunity.</p>
                            <br /><br />
                            <div className='storebtnInFirstFooter'>
                                <img src={playstore} alt="playstorebtn" style={{ marginRight: '10px', cursor: "pointer", zIndex: "1" }}
                                    onClick={() => this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")} />
                                <img src={appstore} alt="appstorebtn" style={{ marginLeft: '10px', cursor: "pointer", zIndex: "1" }}
                                    onClick={() => this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")} />
                            </div>
                        </div>

                        <div className='footerNavber'>
                            <h1 style={{ color: "white" }}>MazingDay</h1>
                            <h3 style={{ color: "white", fontWeight: "400", cursor: "pointer" }} onClick={() => navigate('/')}>Home</h3>
                            <h3 style={{ color: "white", fontWeight: "400", cursor: "pointer" }} onClick={() => navigate('/provider')}>I am a provider</h3>
                            <h3 style={{ color: "white", fontWeight: "400", cursor: "pointer" }} onClick={() => navigate('/contact')}>Contact</h3>
                            <h3 style={{ color: "white", fontWeight: "400", cursor: "pointer" }} onClick={() => navigate('/faq')}>FAQs</h3>
                        </div>

                        <div className='footerSocial'>
                            <h1 style={{ color: "white" }}>Contact</h1>
                            <p onClick={() => window.location = 'mailto:info@mazingday.com'} style={{ color: "white", cursor: "pointer", display: "flex" }}>
                                <img src={email} alt="email" />info@mazingday.com
                            </p>
                            <div className="social">
                                <a href={config.facebook} target="_blank" title="Facebook" rel="noreferrer"><img src={facebook} alt="facebook" /></a>
                                <a href={config.instagram} target="_blank" style={{ position: "relative", left: "10px" }} title="Instagram" rel="noreferrer"><img src={insta} alt="instagram" /></a>
                                <a href={config.linkedin} target="_blank" style={{ position: "relative", left: "20px" }} title="Linkedin" rel="noreferrer"><img src={linkedin} alt="linkedin" /></a>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className='footerText'>
                        <h1><span>©️</span>2022 MazingDay All rights reserved</h1>
                        <h4>MazingDay® is a brand registered in Benelux since April 2021</h4>
                        <p>MazingDay is a product of Alpeyg company, a partnership limited by shares,</p>
                        <p>legally registered with the Luxembourg Trade & Companies Register - License number B25364</p>
                        <p>MazingDay Fze, UAE branch of Alpeyg, is located in Dubai Silicon Oasis</p>
                    </div>
                </div>
            </div>
        );

        const MobileFooterContent = () => (
            <div className='mobileFooter' style={{ height: "440px", backgroundColor: "#01033D" }}>
                <div className='footerFirst'>
                    <div className='textContainingFooter'>
                        <h1>Make any moment an opportunity.</h1>
                    </div>
                    <div className='downloadButton'>
                        <button className="download-btn" style={{
                            border: "none", borderRadius: "15px", color: "#fff", padding: "10px 20px", cursor: "pointer",
                            fontSize: "12px", fontWeight: "bold", width: "100%", textAlign: "center", marginTop: "10px",
                            marginBottom: "10px", paddingTop: "15px", paddingBottom: "15px"
                        }}
                            onClick={() => {
                                if (this.whatMobile === "ios" || this.whatMobile === "unknown")
                                    this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415");
                                else
                                    this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1");
                            }}>
                            Download the app
                        </button>
                    </div>
                </div>

                <div className='footerSecond'>
                    <div className='footerMobileLogo'>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className='listMobileFooter'>
                        <h1>MazingDay</h1>
                        <h3 onClick={() => navigate('/')}>Home</h3>
                        <h3 onClick={() => navigate('/provider')}>I am a provider</h3>
                        <h3 onClick={() => navigate('/contact')}>Contact</h3>
                        <h3 onClick={() => navigate('/faq')}>FAQs</h3>
                    </div>

                    <div className='socialFooter'>
                        <h1>Contact</h1>
                        <p onClick={() => window.location = 'mailto:info@mazingday.com'}>
                            <img src={email} alt="email" />info@mazingday.com
                        </p>
                        <div className="social">
                            <a href={config.facebook} target="_blank" title="Facebook" rel="noreferrer"><img src={facebook} alt="facebook" /></a>
                            <a href={config.instagram} target="_blank" style={{ position: "relative", left: "10px" }} title="Instagram" rel="noreferrer"><img src={insta} alt="instagram" /></a>
                            <a href={config.linkedin} target="_blank" style={{ position: "relative", left: "20px" }} title="Linkedin" rel="noreferrer"><img src={linkedin} alt="linkedin" /></a>
                        </div>
                    </div>
                </div>

                <hr />

                <div className='footerThird'>
                    <h1>©️ 2022 MazingDay All rights reserved</h1>
                    <h4>MazingDay® is a brand registered in Benelux since April 2021</h4>
                    <p>MazingDay is a product of Alpeyg company, a partnership limited by shares,</p>
                    <p>legally registered with the Luxembourg Trade & Companies Register - License number B253648</p>
                    <p>MazingDay Fze, UAE branch of Alpeyg, is located in Dubai Silicon Oasis</p>
                </div>
            </div>
        );

        return isSmallScreen ? <MobileFooterContent /> : <FooterContent />;
    }
}

export default Footer;