import React, { Component } from 'react';
import config from '../config';

class Contact extends Component {
    state = {
        fname:'',
        lname:'',
        email:'',
        desc:'',
        message:''
      };
     
    
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if(this.state.fname && this.state.lname){
            let res = await fetch(`${config.API_URL}/users`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                fname: this.state.fname,
                lname: this.state.lname,
                email: this.state.email,
                desc: this.state.desc,
                formType:'contact',
              }),
            });
            if (res.status === 200) {
              this.setState({message : 'Thank you for reaching out to us We will get back to you soon'});
              this.setState({fname:'',lname:'',email:'',desc:''});
            } else {
              this.setState({message : "Some error occured"});
            }
          }
        } catch (err) {
          console.log(err);
        }
      };
      
    render() {
        return (
            <div className='contancContainer'>
                <div className='textInContact'>
                    <h1>Contact us</h1>
                    <p>
                    Need to get in touch with us? Either fill out the form with your inquiry or send us a <span
                    style={{
                        color: "#448DF0",
                        cursor: "pointer"
                    }}
                    onClick={()=>{
                      window.location.href = 'mailto:info@mazingday.com'
                    }}
                    > direct email</span>.
                    </p>
                </div>
                <div className='inputInContact'>
                    {/* <form onSubmit={this.handleSubmit}> */}
                        <input
                        placeholder='First Name*'
                        type="text"
                        value={this.state.fname}
                        onChange={(e) => this.setState({fname : e.target.value})}
                        />
                        <input
                        placeholder='Last Name*'
                        type="text"
                        value={this.state.lname}
                        onChange={(e) => this.setState({lname : e.target.value})}
                        />
                        <input
                        placeholder='Email'
                        type="text"
                        value={this.state.email}
                        onChange={(e) => this.setState({email : e.target.value})}
                        />
                        <textarea
                        type="text"
                        value={this.state.desc}
                        style={{
                            height: "150px"
                        }}
                        onChange={(e) => this.setState({desc : e.target.value})}
                        cols="40" rows="5"
                        />
                        <button onClick={this.handleSubmit}>
                            contact us
                        </button>
                        <div className="message">{this.state.message ? <p>{this.state.message}</p> : null}</div>

                    {/* </form> */}

                </div>
            </div>
        );
    }
}

export default Contact;