import React, { Component } from 'react';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';

class Faq extends Component {
    
    state = {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
        isSmallScreen: window.innerWidth < 1024,
      }
      openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
      };
    render() {
        const { navigate } = this.props;


        const one =() =>{
            return(
                <div className='faqContentBody'>
                    <p>
                    MazingDay wants you to make the most of every moment.
                    To reach this goal, we ask you to provide personal information, so that we know you better.
                    Once we do, our algorithm finds the sequence of activities that suits you and 
                    your schedule best. Either you like it and start your Mazingday, or you don’t and swipe
                    till the shoe fits your foot.
                    </p>
                </div>
            );
        }
        const two =()=>{
            return(
                <div className='faqContentBody'>
                    <p>
                    MazingDay is aimed at everyone from 13 to 113 years old. No matter your age, gender, nationality, 
                    education, religion, skills… MazingDay will match who you are but also who you might 
                    be, by meeting your unconscious expectations and helping you realize your full potential.
                    Let the app surprise you and take you where you have never imagined. 
                    Dare saying YES and enjoy the journey!
                    </p>
                </div>
            );
        }
        const three =()=>{
            return(
                <div className='faqContentBody'>
                    <p>
                    Activities picked up by our algorithm 
                    are classified in more than 10 categories, 
                    exhaustively covering anything you can
                    do outside home. The app is constantly 
                    updated and the diversity of content
                    is ever growing. Stay in or step out of 
                    your comfort zone? </p><p>
                    Be reassured or be surprised? You decide. 
                    MazingDay is here to accompany you
                    into a real, steady and fulfilling personal 
                    growth, always with fun along the way.
                    </p>
                </div>
            );
        }
        const four =()=>{
            return(
                <div className='faqContentBody'>
                    <p>
                    Our algorithm will take care of it for you! Depending on your availability, the activities will be 
                    sequenced smoothly within your allotted time. You are free from morning til night?
                    The app will suggest you activities for the whole day, including lunch and dinner breaks, 
                    taking into consideration your mode of transportation and your availability. You have started a sequence of activities 
                    but can’t make it to the next one because of traffic, something unforeseen or simply
                    because you changed your mind? No problem at all! MazingDay is not a booking platform, 
                    thus plans can be changed whenever you want.
                    </p>
                </div>
            );
        }
        const five =()=>{
            return(
                <div className='faqContentBody'>
                    <p>
                    Let’s be very clear: we commit not to sell or disclose any personal information 
                    without your consent. This being said, any personal data that we might ask 
                    (age, gender, nationality, religion, height/weight, health, mobility limitation, etc.) 
                    is collected only with the purpose of matching our activities suggestions 
                    with your profile.
                    </p>
                </div>
            );
        }
        const six =()=>{
            return(
                <div className='faqContentBodySix'>
                    <p>
                    MazingDay offers you the following subscription plans to fit your needs:
                    </p>
                    <ul>
                        <li>
                           One month
                            
                        </li>
                        <li>
                            Three months
                        </li>
                        <li>
                            One year
                        </li>
                    </ul>

                    <p>
                     <span
                    style={{
                        color: "#448DF0",
                        cursor: "pointer"
                    }}
                    onClick={()=>{
                        if(this.whatMobile === "ios" || this.whatMobile === "unknown" )
                        this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        else
                        this.openInNewTab("https://play.google.com/store/apps/details?id=com.alpeyg.mazingday&pli=1")

                    }}
                    > Download </span> MazingDay mobile app from <span
                    style={{
                        color: "#448DF0",
                        cursor: "pointer"
                    }}
                    onClick={
                        ()=>{
                            this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        }
                    }
                    > Google Play Store </span>  or  <span
                    style={{
                        color: "#448DF0",
                        cursor: "pointer"
                    }}
                    onClick={
                        ()=>{
                            this.openInNewTab("https://apps.apple.com/ae/app/mazingday/id1588274415")
                        }
                    }
                    > App Store </span>
                    and follow the steps!
                    </p>

                </div>
            );
        }
        const seven =()=>{
            return(
                <div className='faqContentBodySeven'>
                    <p>
                    We are happy to welcome you in our Mazing network! MazingDay is a wonderful tool for you as it matches your offer of activities or services with the right customer base. Thus, you can increase your visibility with relevant and useful reviews adhering to our code of conduct. You can also increase your visitor and sales conversion rates, providing additional revenue. Last but not least, you can also make new business connections or even find potential new talents amongst your visitors. 
                    <span
                    style={{
                        color: "blue",
                        cursor: "pointer"
                    }}
                    onClick={()=>{
                        navigate('/provider')
                    }}
                    > Contact us </span>
                      to start our journey together.
                    </p>
                </div>
            );
        }

        return (
            <div className='faqContainerApp' >
                <header className='faqHeader'>
                    { this.state.isSmallScreen === false ?
                <h1
                className='titleInFaqApp'
                >
                Frequently asked 
                questions
                </h1>
                :
                <h1 
                className='titleInFaqApp'

                style={{
                    fontWeight: "bold"
                }} >
                    FAQs
                </h1>
                }
                </header>

                <div className='faq'>
                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        How does Mazingday work?
                        </h2>

                        {
                            this.state.one === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD",
                        }}
                        onClick={
                            () => {
                                this.setState({one: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({one: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.one === true ?
                            one()
                            :
                            null
                        }
                </div>


                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        Who can use Mazingday?
                        </h2>

                        {
                            this.state.two === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({two: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({two: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.two === true ?
                            two()
                            :
                            null
                        }
                </div>
                    

                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        What kind of activities will I get to do?
                        </h2>

                        {
                            this.state.three === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({three: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD",
                        }}
                        onClick={
                            () => {
                                this.setState({three: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.three === true ?
                            three()
                            :
                            null
                        }
                </div>


                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        There Is So Much To Do! How Do I Choose The Type Of Activities That I Want To Do?
                        </h2>

                        {
                            this.state.four === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({four: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({four: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.four === true ?
                            four()
                            :
                            null
                        }
                </div>


                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        It all sounds great, but what about my private data?
                        </h2>

                        {
                            this.state.five === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({five: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({five: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.five === true ?
                            five()
                            :
                            null
                        }
                </div>


                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        Amazing! How do I subscribe?
                        </h2>

                        {
                            this.state.six === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({six: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({six: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.six === true ?
                            six()
                            :
                            null
                        }
                </div>


                <div className='faqContent'>
                    <div className='faqContentHead'>
                        <h2 className='faqContentTitle'>
                        I am a Provider, How Can I Register My Activities Or Services In Your Database?
                        </h2>

                        {
                            this.state.seven === false ?
                        <AddCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({seven: true})
                            }
                        }
                        />
                            :
                        <RemoveCircleOutlineTwoToneIcon className='faqContentIcon'
                        sx={{
                            fontSize: this.state.isSmallScreen ? "22px" : "52px",
                            color: "#BDBDBD"
                        }}
                        onClick={
                            () => {
                                this.setState({seven: false})
                            }
                        }
                        />
                        }
                    </div>
                        {
                            this.state.seven === true ?
                            seven()
                            :
                            null
                        }
                </div>
                </div>
            </div>
        );
    }
}

export default Faq;