import React, { Component } from 'react';
// import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
// import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone';

class Policy extends Component {
    // const { width, hight} = useDimensions().windows;
    

    render() {

        return (
            <div style={{
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem"
            }} >
            <h1>Privacy Policy</h1> 
            <h8>Privacy Policy Effective and last revised on Apr. 2023</h8>
                        <p> This Privacy Policy describes the data protection collect by ALPEYG/MAZINGDAY APP (“we,” “us,”
                            “our App”, or “our”). We have created this Privacy Policy to ensure that you understand our
                            policies and procedures relating to privacy of your data, what personal information you must
                            provide when you access or use MAZINGDAY mobile application, website, or other online or
                            mobile service that links to this Privacy Policy to you, and how we use such personal information.
                            We refer to these products and services collectively as the “Services.”
                          </p> <p>By accessing or using any of the Services, you acknowledge and agree that you have read and
                            agree to be bound by this Privacy Policy. <b>IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE
                            DO NOT USE THE SERVICES.</b>
                          </p> <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We
                            will alert you about any changes by updating the “Last updated” date of the Privacy Policy. You are
                            encouraged to periodically review this Privacy Policy to stay informed of updates. You will be
                            deemed to have been made aware of, will be subject to, and will be deemed to have accepted the
                            changes in any revised Privacy Policy by your continued use of the Services after the date such
                            revised Privacy Policy is posted.
                          </p> <p>This Privacy Policy <strong> does not apply to the third-party </strong> online/mobile store from which you install the
                            Application, which may also collect and use data about you. We are not responsible for any of the
                            data collected by any such third party.
                          </p> 
                          <p><strong>1. Personal Information. </strong>To access our App, or any portions where the Services are offered, we
                            require you to register and select a user name and password. Some personal data is collected
                            during the registration process. We may then ask for additional information, including personally
                            identifiable and non-personally identifiable information.
                          </p> <p>When you purchase one of our Services, when you subscribe to the Services, and/or when you
                            enter a contest or other promotion, we may ask you for certain personal information such as your
                            name, address, e-mail address, or credit card number, Demographic information such as your
                            
                            gender or other information you choose to provide as part of your MAZINGDAY profile, User-
                            generated content you provide to us, such as when you comment on content on the Services,
                            
                            respond to a survey request, review a proposal, or participate in the public forums, or any other
                            information you choose to directly provide to us in connection with your use of the Services in
                            order for us to process your order, manage your subscription, administer the contest, or send you
                            promotional e-mails. Providing personal information in these instances is solely your choice; you
                            
                            do not need to provide such information, make purchases, or enter such contests to browse our
                            App, Website, or our Services as a Visitor.
                          </p> <p><strong>2. Information about others. </strong>If you request that your purchase be provided to someone other
                            than yourself (such as a gift recipient), we use the information you provide about the other person
                            to fulfill the shipment.
                          </p> <p><strong>3. Non-Personal Data. </strong>In some cases, we may collect non-personal information. Examples of this
                            type of information include:
                          </p> <ul> <p><strong>Website</strong></p> <p> When you use our website, we collect and analyze information such as your IP address, browser
                            types, browser language, operating system, software and hardware attributes (including device
                            IDs) referring and exit pages and URLs, the number of clicks, pages viewed and the order of those
                            pages, date and time of use, content watched, total minutes watched, error logs, and other similar
                            information about how you use the website. We and our service providers (which are third party
                            companies that work on our behalf) may use a variety of technologies, including cookies and
                            similar tools, to assist in collecting this information. We use your information on an aggregated
                            basis to do such things as operate our Website, enhance our App and sell and deliver advertising.
                          </p> </ul> <ul> <p><strong>Mobile Applications</strong></p> <p> When you use MAZINGDAY mobile application or software ("app"), we automatically receive
                            certain information about the mobile phone, tablet, or computer used to access the app, including
                            device identifiers, IP address, operating system, version, Internet service provider, browser type,
                            domain name and other similar information, whether and when you update the app, date and
                            time of use, content viewed, total proposals added by you to your MAZINGDAY calendar, error
                            logs, and other similar information about how you use the app.
                          </p> </ul> <ul><p><strong>Location Information</strong></p> <p>When you use the Services, we and our service providers may automatically collect general
                            location information (e.g., IP address, city/state and/or postal code associated with an IP address,
                            city/state) from your computer or mobile device.
                          </p> </ul> <ul><p><strong>Social Media and Other Content Platforms</strong></p> <p>If you access the Services through a third-party connection or log-in (e.g., through a social network
                            like Facebook or Instagram, etc.), you may allow us to have access to and store certain information
                            from your social network profile. This can include your name, gender, profile picture, your “likes”
                            
                            and check-ins, and your list of friends, depending on your settings on such services. If you do not
                            wish to have this information disclosed, do not use a social networking connection to access the
                            Services. For a description of how social networking sites handle your information, please refer to
                            their privacy policies and terms of use, which may permit you to modify your privacy settings. You
                            may also have the option of posting your Services activities to Social Networking Services when
                            you access content through the Services (for example, you may post to Facebook or other social
                            media that you subscribe to our App, or you enjoyed the proposal received, or your experience by
                            getting and knowing the activities that you didn’t know before); you acknowledge that if you
                            choose to use this feature, your friends, followers and subscribers on any Social Networking
                            Services you have enabled will be able to view such activity.
                          </p> </ul> <p><strong>4. NON-RESELLING DATA by MAZINGDAY. </strong>MAZINGDAY is proud to inform its users that <strong> We Do
                            Not Resell Your Data </strong> to any third parties for the sake of revenue generation. The business model
                            of MAZINGDAY is based on subscription and not on advertisement to: i) provide curated
                            experience and proposals to our users; ii) not prioritize one supplier over others just because they
                            sponsored or they paid more. Nevertheless, we reserve the right to aggregate all your personal
                            data for statistics generation purpose that can be shared with our third parties.
                          </p> <p><strong>5. How We Use Your Information. </strong>We use the information collected on our App for a variety of
                            purposes, including, but not limited to:
                          </p><ul><p><strong>Provide the Services</strong></p> <p> - Complete a purchase or provide the Services you have requested, including invoicing and
                            accounting
                        </p></ul> <ul><p><strong>Communicate with you and provide support</strong></p> <p> - Respond to your request for information and provide you with more effective and efficient
                            customer service</p><p>
                            - Provide you with updates and information about new events or activities added to the App
                        </p></ul> <ul> <p><strong>Marketing and content customization</strong></p> <p> - Contact you by email, postal mail, or phone regarding MAZINGDAY and third-party
                            products, services, surveys, research studies, promotions, special events and other
                            subjects that we think may be of interest to you. </p><p>
                            - Customize the advertising and content you see on the Services
                        </p></ul> <ul> <p><strong>Business operations</strong></p> <p> - Help us better understand your interests and needs, and improve the Services, including
                            through research and reports, and test and create new products, features, and services</p><p>
                            - Secure our website and applications, and resolve app crashes and other issues being
                            reported
                        </p></ul> <ul><p><strong>Legal Purposes</strong></p> <p>- Comply with any procedures, laws, and regulations which apply to us where it is necessary
                            for our legitimate interests or the legitimate interests of others. </p><p>
                            - Establish, exercise, or defend our legal rights where it is necessary for our legitimate
                            interests or the legitimate interests of others
                        </p> </ul> <p>You can remove your e-mail address from our e-mail list at any time by following the procedures
                            set forth in the Section 6 below (“Opt Out Option”) or by clicking on the "unsubscribe" link in every
                            e-mail from MAZINGDAY. In addition, you can modify your information or change your
                            preferences, as set forth in Section 7 below (“Reviewing or Changing Your Information”).
                            Information obtained through the App, Website and/or the Services may be intermingled with and
                            used in conjunction with information obtained through sources other than our App, including both
                            offline and online sources.
                        </p><p><strong>6. Cookies.</strong></p> <p>Certain features on the Website and/or the Services utilize cookie technology. Cookies  are small text files that are placed on your computer or mobile device when you visit a site that  enable us to track the pages you've visited. We may use cookies to enhance your experience on  our Websites or App, to determine user traffic patterns and for other purposes. 
                        </p> <p>Most browsers are initially set up to accept cookies; however, you can reset your browser to refuse  all cookies or indicate when a cookie is being sent or you can flush your browser of cookies from  time to time. (Note: you may need to consult the help area of your browser application for  instructions.) If you choose to disable your cookies setting or refuse to accept a cookie, however,  you may not be able to access all areas of the Website and/or the Services. 
                        </p> <p>We also use cookies to improve the performance of our advertising on other websites. Although  you may not opt out of receiving online advertisements generally, you may control the collection  of data on our site used for targeted mazingday.com advertising during your visits to other  websites.  
                        </p><p><strong>7. Opt Out Option. </strong></p> <p>You have the option to opt out of receiving information from MAZINGDAY. This  opt out messaging will appear at the bottom of every promotional email that is sent out. If you no  longer wish to take advantage of the Services or receive any form of direct contact from 
                                        MAZINGDAY, whether it is email, discounts, newsletters, or other promotional offers or materials,  you may use the opt out option on that particular email. 
                        </p><p><strong>8. Reviewing or Changing Your Information.</strong></p> <p>We give users the option to change or modify their  information previously provided during registration. If you would like to change your information  at any time in our database please log in and click the “Profile” link on the App to do so.  
                        </p><p><strong>9. Sharing of Your Information.</strong></p> <p>We may share your personal information: (i) with our parent,  affiliates, subsidiaries, or successors (collectively the “ALPEYG/MAZINGDAY Parties”), or with third  parties who are under obligations of confidentiality with any of the MAZINGDAY Parties, (ii) if a  MAZINGDAY Party is required by law to do so, (iii) in the event of a transfer of ownership of a  MAZINGDAY Party, merger or other similar transaction, or (iv) as otherwise set forth in this Privacy  Policy. The following describes some of the ways that your personal information may be disclosed  to third parties:  
                        </p><p>We may employ third parties to perform services or functions on our behalf in order to improve  our Website, marketing and promotional efforts, communications or other services. Those third  parties may include authorized contractors, consultants and other companies working with us.  They only have access to personal information needed to perform their functions, and they may  not share any personal information with others or use it for any other purpose than providing or  improving MAZINGDAY's services and offerings.  
                        </p><p>We may share your information with third parties whom we believe have information of interest  to you. In addition, we may share certain non-personal information with third parties for  advertising, promotional and other purposes. For example, we may work with third party  advertising companies, to serve and track our ads. These third parties may install other cookies.  Our advertising partners may use the non-personal information they collect from our Website or  App, in the aggregate, to help us better market and serve our customers.  
                        </p><p>This Privacy Policy applies only to our App, Website and the Services and does not address the  practices of third parties who may collect your personal information. You may visit other websites,  through links on the Websites, which may collect, use and share your personal information in  accordance with their own privacy policies. The information practices of those linked websites are  not covered by this Privacy Policy, and we encourage you to be very cautious before you disclose  your personal information to others. In order to provide you with the information, products or  services which you have requested, we may share or transfer your personal information with our  parent, affiliates or subsidiaries, or third party agents acting on their behalf. 
                        </p><p>The MAZINGDAY Parties may be obligated to cooperate with various law enforcement inquiries.  Each MAZINGDAY Party reserves the right to disclose or transfer personal information and non personal information about you and your activities on our Websites in order to comply with a legal 
                                requirement or request from law enforcement or other government officials, administrative  agencies or third parties as we, in our sole discretion, determine necessary or appropriate for the  administration of justice, or in connection with an investigation of fraud, intellectual property  infringements or violations of any other law, rule or regulation, our Terms of Use or other rules or  policies of our App, Website, the rights of third parties, or an investigation of any other activity  that may expose us or you to legal liability, or to investigate any suspected conduct which  MAZINGDAY in its sole discretion deems improper. 
                        </p><p><strong>10. Retention of Your Information.</strong></p> <p>We keep your information for no longer than necessary for  the purposes for which it is processed. The length of time for which we retain information depends  on the purposes for which we collected and use it and/or as required to comply with applicable  laws.  
                        </p><p><strong>11. How We Protect Your Information.</strong></p> <p>MAZINGDAY takes technical and organizational security  measures to protect the information provided via the Services from loss, misuse, and unauthorized  access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever  fully secure or error free. Please keep this in mind when disclosing any information to The App,  Website and The Services via the Internet.  
                        </p><p><strong>12. Security of Your Information. </strong></p> <p>We use administrative, technical, and physical security  measures to help protect your personal information. While we have taken reasonable steps to  secure the personal information you provide to us, please be aware that despite our efforts, no  security measures are perfect or impenetrable, and no method of data transmission can be  guaranteed against any interception or other type of misuse. Any information disclosed online is  vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee  complete security if you provide personal information.  
                        </p><p><strong>13. Children's Privacy.</strong></p> <p>The App and The Services are intended for general audiences and not for  children under the age of 13. If we become aware that we have inadvertently collected “personal  information” from children under the age of 13 without valid parental consent, we will take  reasonable steps to delete it as soon as possible. We also comply with other age restrictions and  requirements in accordance with applicable local laws. Where appropriate, we take reasonable  measures to determine that our users are adults of legal age and to inform minors not to submit  such information to the App or the Services or in response to advertisements. 
                        </p><p><strong>14. Security.</strong></p> <p>Providing a secure site is essential for your peace of mind and trust in  ALPEYG/MAZINGDAY. We have installed encryption software conforming to the Secure Socket  Layers (SSL) protocol to safeguard all of the commerce related (i.e. credit card and billing address)  information you send to us. All information is stored on our servers in a secure location. It is  important for you to protect against unauthorized access to your password and to your computer.  If your password is compromised, notify Customer Service at once at: customer.support@mazingday.com 
 
                        </p><p><strong>15. DO-NOT-TRACK Features.</strong></p> <p>Most web browsers and some mobile operating systems [and our  App] include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy  preference not to have data about your online browsing activities monitored and collected. No  uniform technology standard for recognizing and implementing DNT signals has been finalized. As  such, we do not currently respond to DNT browser signals or any other mechanism that  automatically communicates your choice not to be tracked online. If a standard for online tracking  is adopted that we must follow in the future, we will inform you about that practice in a revised  version of this Privacy Policy.  
                        </p><p><strong>16. Your Consent.</strong></p> <p>By using the App, Website and/or the Services, and by providing your personal  information to us, you also authorize the storage, use and export of your personal information as  specified herein. 
                        </p><p><strong>17. Applicable Law.</strong></p> <p>The headquarters of ALPEYG is located in Luxembourg, 9, rue Large, L- 1917  Luxembourg. This Privacy Policy and our legal obligations are subject to the laws of Luxembourg,  regardless of the location of any user. Any claims or complaints must be filed a court of competent  jurisdiction in Luxembourg. 
                        </p> <p><strong>Contact Us</strong></p> <p>If you have questions or comments about this Privacy Policy, please contact us at:
                        </p>
                        <p>Luxembourg, 9, rue Large, L- 1917 <br />
                        Luxembour<br />
                        info@mazingday.com</p>
            </div>
        );
    }
}

export default Policy;